<template>
	<b-container>
		<b-container fluid>
			<h1>Disciplinas</h1>
			<b-row>
				<b-col>
                    <b-table :fields="fields" :items="disciplinas">
                        <template class="align-check" #cell(rev_1)=" data ">
                            <b-form-checkbox-group
                                v-model="data.item.revisoes"
                                :options="optionsRevisoes[0]"
                                name="flavour-1"
                                @change="[setDisciplinasStorage(), setRecorrencia(data)]"
                            ></b-form-checkbox-group>
                        </template>
                        <template class="align-check" #cell(rev_7)=" data ">
                            <b-form-checkbox-group
                                v-model="data.item.revisoes"
                                :options="optionsRevisoes[1]"
                                name="flavour-1"
                                @change="[setDisciplinasStorage(), setRecorrencia(data)]"
                            ></b-form-checkbox-group>
                        </template>
                        <template class="align-check" #cell(rev_30)=" data ">
                            <b-form-checkbox-group
                                v-model="data.item.revisoes"
                                :options="optionsRevisoes[2]"
                                name="flavour-1"
                                @change="[setDisciplinasStorage(), setRecorrencia(data)]"
                            ></b-form-checkbox-group>
                        </template>
                        <template #cell(recorrente)=" data ">
                            <b-form-checkbox :disabled="data.item.recorrenteEnable" class="align-check" v-model="data.item.recorrente"></b-form-checkbox>
                        </template>
                        <template #cell(acoes)=" data ">
                            <b-button @click="removeDisciplina(data.index)" v-b-tooltip.hover title="Remover Disciplina" class="ml-1" variant="flat-danger">
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </template> 
                    </b-table>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import {
	BRow,
	BCol,
	BButton,
	BFormInput,
	BContainer,
	BFormCheckbox,
	BFormSelect,
    BTable,
    BFormGroup,
    BFormCheckboxGroup,
    VBTooltip
} from "bootstrap-vue";
import store from '@/store'
export default {
    directives:{
        'b-tooltip': VBTooltip
    },
	components: {
		BRow,
		BCol,
		BButton,
		BFormInput,
		BContainer,
		BFormCheckbox,
        BFormCheckboxGroup,
		BFormSelect,
        BTable,
        BFormGroup
	},
	data: () => ({
		concursoExists: false,
		disciplinas: [],
		disciplinaSelected: [],
        concursoId: null,
        cargoId: null,
        editalId: null,
        fields: [
            { key: 'disciplina', label: 'Disciplina'},
            { key: 'rev_1', label: 'Revisões 1'},
            { key: 'rev_7', label: 'Revisões 7'},
            { key: 'rev_30', label: 'Revisões 30'},
            { key: 'recorrente', label: 'Recorrente'},
            { key: 'acoes', label: 'Ações'},
        ],
        optionsRevisoes: [
            [{ text: '', value: 1 }],
            [{ text: '', value: 2 }],
            [{ text: '', value: 3 }],
        ],
        recorrenteEnable: false
	}),
	methods: {
        setRecorrencia(data){
            const { item : { revisoes }, index } = data

            if(revisoes.includes(3)){
                this.disciplinas[index].recorrenteEnable = false
            }else{
                this.disciplinas[index].recorrenteEnable = true
            }
        },
		getAllConcursos() {

            this.$http.get(`concursos/${this.concursoId}/cargos/${this.cargoId}/edital/${this.editalId}/disciplinas`).then((response) => {
				this.disciplinas = response.data.map( disciplina => {
                    return {
                        ...disciplina,
                        disciplina: disciplina.disciplina,
                        revisoes: [],
                        recorrente: false,
                        recorrenteEnable: true
                    }
                });

                this.setDisciplinasStorage()
			});

		},
        removeDisciplina(idx){
            this.disciplinas.splice(idx, 1)
            this.setDisciplinasStorage()
        },
        setDisciplinasStorage(){
            
            let editalData = store.getters['controller/getNovoEdital']
            console.log(editalData)
            store.commit('controller/SET_NOVO_EDITAL', {
                ...editalData,
                disciplinas: this.disciplinas
            })
            this.$emit("can-continue", { value: true });
        }
	},
	created() {
        let editalData = store.getters['controller/getNovoEdital']
        console.log(editalData)
        this.concursoId = editalData.concursoId
        this.cargoId = editalData.cargoId
        this.editalId = editalData.editalId
		this.getAllConcursos();
	},
};
</script>

<style>
.align-check{
    max-width: 18px;
    margin: auto auto;
    text-align: center;
}
</style>