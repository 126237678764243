<template>
	<b-container>
		<b-container fluid>
			<h1>Nome</h1>
			<b-row>
				<b-col>
					<b-form-input
						placeholder="Nome do edital"
						v-model="editalNome"
						:disabled="disbleNome"
					></b-form-input>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import {
	BRow,
	BCol,
	BButton,
	BFormInput,
	BContainer,
	BFormCheckbox,
} from "bootstrap-vue";

import store from '@/store'
export default {
	components: {
		BRow,
		BCol,
		BButton,
		BFormInput,
		BContainer,
		BFormCheckbox,
	},
	data: () => ({
		disbleNome: false,
		editalNome: null,
		timeDigitando: null,
		cicloId: null
	}),
	methods: {
        setNomeConcurso(){
            this.$emit("can-continue", { value: true });

			let concursoData = store.getters['controller/getNovoEdital']
            store.commit('controller/SET_NOVO_EDITAL', {
					...concursoData,
                nome: this.editalNome
            })
		}
	},
	watch: {
		editalNome () {
			clearTimeout(this.timeDigitando);
			if (this.editalNome &&  this.editalNome != '') {
				this.timeDigitando = setTimeout(() => {
					this.setNomeConcurso()
				}, 2000)
			}else{
                this.$emit("can-continue", { value: false });
            }
		},
	}
};
</script>

<style>
</style>