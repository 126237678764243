<template>
	<div>
		<b-container fluid>
			<h1>Novo Edital</h1>
			<br />
			<horizontal-stepper
				:top-buttons="false"
				locale="pt"
				:steps="cicloSteps"
				@completed-step="completeStep"
				@active-step="isStepActive"
				@stepper-finished="addNovoEdital"
			>
			</horizontal-stepper>
		</b-container>
	</div>
</template>

<script>
import { BRow, BCol, BButton, BFormInput, BContainer } from "bootstrap-vue";
import HorizontalStepper from "vue-stepper";
import NomeEdital from "@/views/pages/edital/steps/NomeEdital.vue"
import SelecionarCargo from "@/views/pages/edital/steps/SelecionarCargo.vue"
import SelecionarDisciplinas from "@/views/pages/edital/steps/SelecionarDisciplinas.vue"
import SelecionarConcurso from "@/views/pages/edital/steps/SelecionarConcurso.vue"
import store from '@/store'
export default {
	components: {
		BRow,
		BCol,
		BButton,
		BFormInput,
		BContainer,
		HorizontalStepper
	},
	data: () => ({
		cicloSteps: [
			{
				icon: "navigate_next",
				name: "default",
				title: "Cargos",
				subtitle: "Selecione os Cargos do Edital",
				component: SelecionarCargo,
				completed: false,
			},
			{
				icon: "school",
				name: "primeiro",
				title: "Nome do Edital",
				subtitle: "Defina um nome para o seu novo edital",
				component: NomeEdital,
				completed: false,
			},
			{
				icon: "navigate_next",
				name: "segundo",
				title: "Disciplinas",
				subtitle: "Selecione as Disciplinas",
				component: SelecionarDisciplinas,
				completed: false,
			},
		],
	}),
	methods: {
		completeStep(payload) {
			this.cicloSteps.forEach((step) => {
				if (step.name === payload.name) {
					step.completed = true;
				}
			});
		},
		isStepActive(payload) {
			this.cicloSteps.forEach((step) => {
				if (step.name === payload) {
					if (step.completed === true) {
						step.completed = false;
					}
				}
			});
		},
		addNovoEdital(payload) {
			let editalData = store.getters['controller/getNovoEdital']
			this.$http.post(`editais/${editalData.editalId}/usuario/store`, editalData).then( response => {
				this.$router.push({ path: `/pages/edital-verticalizado` })
			})
		},
		setStepSelecionarConcurso(){
			if(this.tipoId == 1){
				this.cicloSteps.splice(0, 0, {
					icon: "navigate_next",
					name: "select-",
					title: "Concurso",
					subtitle: "Selecione o concurso para o Edital",
					component: SelecionarConcurso,
					completed: false,
				})
			}
		}
	},
  mounted(){
    this.editalId = this.$route.params.idEdital
	this.tipoId = this.$route.params.idTipo
	this.setStepSelecionarConcurso()
  }
};
</script>

<style>
.stepper-button,
.material-icons {
	background-color: #7367f0 !important;
	color: #fff;
}

.deactivated > .material-icons {
	background-color: #cccccc !important;
}

.previous span {
	color: #fff;
}
</style>