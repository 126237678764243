<template>
	<b-container>
		<b-container fluid>
			<h1>Cargo</h1>
			<b-row>
				<b-col>
					<b-form-select
						v-model="cargoSelected"
						value-field="id"
						text-field="nome"
						:options="cargos"
					></b-form-select>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import {
	BRow,
	BCol,
	BButton,
	BFormInput,
	BContainer,
	BFormCheckbox,
	BFormSelect,
} from "bootstrap-vue";
import store from '@/store'
export default {
	components: {
		BRow,
		BCol,
		BButton,
		BFormInput,
		BContainer,
		BFormCheckbox,
		BFormSelect,
	},
	data: () => ({
		cargos: [],
		cargoSelected: null,
		concursoId: null
	}),
	watch: {
		async cargoSelected() {
			if (this.cargoSelected) {
				const editalId = await this.findEditalId(this.cargoSelected);
				this.$emit("can-continue", { value: true });
				let editalData = store.getters['controller/getNovoEdital']
				store.commit('controller/SET_NOVO_EDITAL', {
					...editalData,
					editalId: editalId,
					cargoId: this.cargoSelected
				})
				console.log(store.getters['controller/getNovoEdital'])
				this.$root.$emit('change-cargo-id')
			}
		},
	},
	methods: {
		async getAllCargos() {
			const ciclo = store.getters['controller/getNovoEdital']
			this.concursoId = await ciclo.concursoId

			this.$http.get(`/concursos/${this.concursoId}/cargos`).then((response) => {
				this.cargos = response.data;
			});
		},
		async findEditalId(cargo_id)
		{
			let cargo = await this.cargos.find((element) => {
				if(element.id == cargo_id)
					return element
			})

			return cargo.edital_id;

		}
	},
	created() {
		this.getAllCargos();
		this.$root.$on('change-concurso-id', () => {
			this.getAllCargos();
		})
	},
};
</script>

<style>
</style>